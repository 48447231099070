@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css');

body {
  margin: 0;
  font-family: Pretendard;
}

*:not(.react-codemirror2 *, .monospace *, input) {
  font-family: Pretendard;
}

.monospace,
code,
input {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
