.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menuBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftMenu,
.rightMenu {
  display: flex;
}

.barsMenu {
  display: none;
}

@media (max-width: 768px) {
  .leftMenu,
  .rightMenu {
    display: none;
  }
  .barsMenu {
    display: block;
  }
}

.footer-link {
  color: #1e90ff;
  cursor: pointer;
}

.footer-link:hover {
  text-decoration: underline;
}

body {
  overflow-x: hidden;
}

h2 {
  margin: 10px 0px;
}
