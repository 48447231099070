/* styles.module.css */

.navbar {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  border-bottom: 1px solid rgb(74, 74, 74);
}

.navContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  max-width: 1200px;
  position: relative;
}

.navLogo {
  color: white;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-items: center;
}

.navLogo .icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-left: 16px;
}

.navMenu {
  display: flex;
  list-style: none;
  text-align: center;
  padding-left: 0px;

  justify-content: center;

  flex-grow: 1;
}

.navRight {
  display: flex;
  align-items: center;
}

.navLinks {
  color: #e5e5e5;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  font-size: 0.9rem;
}

.navLinks:hover {
  color: #c5c5c5;
}

.navItem {
  line-height: 40px;
}

.navItem:after {
  content: '';
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.navIcon {
  display: none;
  color: white;
}

@media screen and (max-width: 760px) {
  .navRight {
    display: none;
  }
  .navMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: -410%;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 0px;
  }

  .navMenu.active {
    background: black;
    top: 70px;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navLinks {
    padding: 1.5rem;
    width: 100%;
    display: table;

    border: 1px solid #c5c5c5;
  }

  .navLogo .icon {
    width: 2rem;
    height: 2rem;
  }

  .navIcon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: white;
  }
}
